import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";

import HButton from "../../../../components/common/hbutton/HButton";
import { TopUpItem } from "../../../details/components/active/TopUpItem";
import { SkeletonAddData } from "../../../details/components/active/SkeletonAddData";
import { ContentModal } from "../../../../components/modal/ContentModal";
import { PackagePurchaseConfirmationModal } from "../../../details/components/PackagePurchaseConfirmationModal";

import { buyBundle } from "../../../../services/topup";
import { useCustomerStore } from "../../../../store/customerStore";

import { ICustomerStore, ITopupModalProps, TopUp } from "../../../../interfaces";
import { getCurrencyData } from "../../../../utils/getCurrencyData";

const disableButton: string = "bg-neutral-300";

const activeButton: string =
  "cursor-pointer transition-all duration-300 bg-digital-green border-digital-green hover:bg-brand-green-500 active:bg-semantic-success-400 hover:border-brand-green-500 hover:text-white";

export const TopupModal = ({
  closeModal,
  loading,
  data,
}: ITopupModalProps): JSX.Element => {
  const { topUps, dataDetail } = data;
  const { t } = useTranslation();
  const { language, currency } = useCustomerStore((store: ICustomerStore) => store);
  const [isModalTopUp, setIsModalTopUp] = useState<boolean>(false);
  const [currentTopUp, setCurrentTopUp] = useState<TopUp>();

  const [item, setItem] = useState({} as TopUp);

  const buyTopup = async (bundleSelected: any) => {
    buyBundle(
      dataDetail,
      bundleSelected,
      getCurrencyData(currency).geolocation,
      language.toUpperCase()
    );
  };
  console.log(topUps)
  const toggleModalTopUp = () => setIsModalTopUp(!isModalTopUp);

  const handleClick = (item: TopUp) => {
    toggleModalTopUp();
    setItem(item);
  };

  const handleChangeCurrentTop = (item: TopUp) => {
    setCurrentTopUp(item);
  };

  const updateAdjustTemplate = (): string => {
    switch (topUps.length) {
      case 1:
        return "flex flex-row justify-center w-full md:w-96 mx-auto";

      case 2:
        return "flex flex-col lg:flex-row";

      default:
        return "grid grid-cols-1 lg:grid-cols-3";
    }
  };

  return (
    <>
      <div
        data-testid="topup-modal"
        className={`relative bg-white px-4 py-6 md:p-[50px] w-full  ${topUps.length > 2 ? "h-[500px] lg:h-auto" : "h-auto"
          }  lg:w-[808px] rounded-xl flex flex-col justify-between mx-6 lg:m-auto gap-4`}
      >
        <div className="flex flex-col gap-6">
          <div
            data-testid="close-topup-modal"
            onClick={closeModal}
            className="absolute top-4 right-4 border border-neutral-300 hover:bg-neutral-200 rounded-full 
          w-8 h-8 flex justify-center items-center transition-all duration-300"
          >
            <Icon path={mdiClose} size={0.8} />
          </div>

          <div className="flex flex-col justify-center gap-3 items-center">
            <h2 className="text-neutral-charcoal font-medium text-xl">
              {t("global.customerV2.modalTopUpHome.title")}
            </h2>
            <p className="text-neutral-charcoal font-normal text-base w-full md:w-[32rem] text-center">
              {t("global.customerV2.modalTopUpHome.description")}
            </p>
          </div>
          <div
            className={`overflow-y-auto ${topUps.length > 2 ? "h-52 lg:h-auto" : "h-auto"
              }`}
          >
            {loading ? (
              <div className="md:px-[31px]">
                <SkeletonAddData num={3} />
              </div>
            ) : (
              <div
                data-testid="adjust-template-cols"
                className={`w-full p-0 items-start gap-[8px] ${updateAdjustTemplate()}`}
              >
                {topUps.map((item, index) => (
                  <TopUpItem
                    key={`${item.id}-${index}`}
                    isActive={item.id === currentTopUp?.id}
                    data={item}
                    isFullWidth={topUps.length > 2}
                    handleChangeActive={handleChangeCurrentTop}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
        <HButton
          type="button"
          name="button buy topup"
          className={`${!currentTopUp ? disableButton : activeButton
            }  min-h-[48px] font-thin px-5 py-3 rounded-[14px] ${!currentTopUp ? "" : "cursor-pointer"
            }`}
          containerStyles="tooltip-active min-w-[197px] mx-auto mt-[10px]"
          text={t("global.customerV2.buttons.buttonBuyPackage")}
          disabled={!currentTopUp}
          onClick={() => handleClick(currentTopUp!)}
        />
      </div>

      <ContentModal isModal={isModalTopUp} toggleModal={toggleModalTopUp}>
        <PackagePurchaseConfirmationModal
          data={dataDetail}
          topup={item}
          onClick={() => buyTopup(item)}
          toggleModal={toggleModalTopUp}
        />
      </ContentModal>
    </>
  );
};
