import { Trans, useTranslation } from "react-i18next";
import {
  FacebookLight,
  InstagramLight,
  YoutubeLight,
  LinkedinLight,
  HolaflyLogo,
  footerWaveBottom,
  Heart,
  XTwitter,
} from "../../../../components/svg";

import { DropdownFooter } from "../../../../components/dropdowns/DropdownFooter";

import AndroidButton from "../../../../assets/footer/AndroidButton.svg";
import AppStoreButton from "../../../../assets/footer/IOSButton.svg";
import { LIST_FOOTER_LOYALTY_LANDING } from "../../../../constants";
import { useScreen } from "../../../../hooks/useScreen";

export const Footer = (): JSX.Element => {
  const screen = useScreen();
  const { t } = useTranslation();

  const handleOpenStoreApp = (isIos: boolean) => {
    const platform: string = isIos
      ? "downloadAppUrlIos"
      : "downloadAppUrlAndroid";
    window.open(
      t(`global.header.${platform}`),
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <footer data-testid="footer" className="relative w-full bottom-0 ">
      <img
        draggable={false}
        src={footerWaveBottom}
        alt="footer_wave"
        className="w-full relative md:top-1"
      />
      <div className="bg-neutral-200 w-full">
        <div className="w-full max-w-[1440px] m-auto bg-neutral-200 h-full  md:gap-8 lg:gap-3 py-12 lg:px-28 flex flex-wrap items-center md:items-start  justify-between">
          <div className="flex flex-col items-center lg:items-start justify-center lg:justify-start lg:w-auto w-full gap-4 md:gap-0">
            <HolaflyLogo className="h-12 w-28" color="#E6485C" />

            <div
              className="flex gap-[20px] my-[21px]"
              data-testid="download-icons"
            >
              <img
                onClick={() => handleOpenStoreApp(true)}
                className="w-[140px] cursor-pointer"
                src={AppStoreButton}
                alt="Ios button"
                draggable={false}
              />
              <img
                onClick={() => handleOpenStoreApp(false)}
                className="w-[140px] cursor-pointer"
                src={AndroidButton}
                alt="Android button"
                draggable={false}
              />
            </div>

            <div
              className="flex flex-row items-center gap-1"
              data-testid="social-media-icons"
            >
              <a
                target="_blank"
                rel="noreferrer"
                href={t("global.footer.facebookLink")}
              >
                <FacebookLight className="p-2 w-10 h-10 " />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href={t("global.footer.instagramLink")}
              >
                <InstagramLight className="p-2 w-10 h-10 " />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href={t("global.footer.youtube")}
              >
                <YoutubeLight className="p-2 w-10 h-10 " />
              </a>

              <a
                target="_blank"
                rel="noreferrer"
                href={t("global.footer.twitterLink")}
              >
                <XTwitter className="p-2 w-10 h-10 " color="#000" />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href={t("global.footer.linkedinLink")}
              >
                <LinkedinLight className="p-2 w-10 h-10 " />
              </a>
            </div>
          </div>

          {screen <= 742 ? (
            <div className="flex w-full my-6 flex-col gap-2">
              <DropdownFooter
                title={LIST_FOOTER_LOYALTY_LANDING[0].title}
                opts={LIST_FOOTER_LOYALTY_LANDING[0].subItems}
              />

              <DropdownFooter
                title={LIST_FOOTER_LOYALTY_LANDING[1].title}
                opts={LIST_FOOTER_LOYALTY_LANDING[1].subItems}
              />

              <DropdownFooter
                title={LIST_FOOTER_LOYALTY_LANDING[2].title}
                opts={LIST_FOOTER_LOYALTY_LANDING[2].subItems}
              />
            </div>
          ) : (
            <div
              className="flex w-full justify-center lg:w-auto text-center lg:text-start flex-row gap-[60px]"
              data-testid="desktop-footer-items"
            >
              {LIST_FOOTER_LOYALTY_LANDING.map(({ title, subItems }) => (
                <div className="mt-5 md:mt-0" key={title}>
                  <h4 className="font-bold text-lg ">{t(title)}</h4>

                  <ul className="mt-4">
                    {subItems.map((suboption, index) => (
                      <li key={index} className="my-2 whitespace-nowrap">
                        <a
                          className="font-normal text-sm text-neutral-charcoal hover:underline"
                          target="_blank"
                          rel="noreferrer"
                          href={t(suboption.url)}
                        >
                          {t(suboption.text)}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="md:border-t" />
        <div className=" w-full max-w-[1440px] m-auto flex flex-col items-center px-6 gap-8 md:gap-0 md:flex-row md:justify-between pb-6 md:py-4 md:px-[108px] bg-neutral-200">
          <div className="w-full text-center">
            <Trans
              className="font-medium text-sm text-neutral-charcoal"
              i18nKey="global.footer.madeWithLove"
              components={{
                heart: (
                  <Heart className={"inline h-5 w-5 mx-1"} color={"#FF595C"} />
                ),
              }}
            />
          </div>
        </div>
      </div>
    </footer>
  );
};
