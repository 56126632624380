import { useCustomerStore } from "../../../../store/customerStore";

import { ICustomerStore, ITopUpItemProps } from "../../../../interfaces";

export const TopUpItem = ({
  isFullWidth,
  data,
  isActive,
  handleChangeActive,
}: ITopUpItemProps) => {
  const responsive = isFullWidth ? "lg:w-full" : "lg:w-[210px]";
  const language = useCustomerStore((state: ICustomerStore) => state.language);


  // const days = t("global.details.numberDays", {
  //   days: 25,
  // }).split("25")[1];

  const activeStyles = "border-[#48EC86] bg-[#F5FFF9]";
  const basicStyles = "border-[#E4E6EA] bg-white";
  const checkBoxBasic = "bg-white";
  const checkBoxActive = "bg-[#48EC86]";

  const separationText = data.name[language]?.match(/^(\S+\s+\S+)\s+(.*)$/);
  const titleTopup = separationText ? separationText[1] : data.name[language];
  const subtitleTopup = separationText ? separationText[2] : "";
  return (
    <div
      onClick={() => handleChangeActive(data)}
      className={`${isActive ? activeStyles : basicStyles
        }  min-h-[66px] h-full w-full ${responsive} 
       border rounded-[12px] p-[12px] flex items-center gap-2 cursor-pointer `}>
      <div
        className={`${isActive ? checkBoxActive : checkBoxBasic
          } min-w-[18px] h-[18px] rounded-full border border-[#CCD0D7] p-0 flex justify-center items-center  `}>
        {isActive && (
          <div className="w-[6px] h-[6px] bg-white rounded-full"></div>
        )}
      </div>
      <div className="w-full h-full min-w-[103px] flex flex-col ">
        <h3 className="text-[16px] font-bold whitespace-normal break-words break-all">
          {/* {`${data.name[lang].split(days)[0]} ${days}`} */}
          {titleTopup}
        </h3>
        <p className="text-[14px] font-normal text-[#8A9099]">
          {/* {`${data.name[lang].split(days)[1]}`} */}
          {subtitleTopup}
        </p>
      </div>
      <h3 className="text-[16px] font-bold">{data.price}</h3>
    </div>
  );
};
